import React from 'react';
import { Link } from 'gatsby';

// This file is used at : [ careers.js | successStories.js ]

const SuccessStoriesHomeComponent = props => {
  const { successStoriesHomeTitle, successStoriesHomeDescription } = props;
  return (
    <div className='successStories-home-section'>
      <div className='successStories-home-section-wrapper'>
        <h1
          className='successStories-home-section-title'
          dangerouslySetInnerHTML={{ __html: successStoriesHomeTitle }}
        />
        <p
          className='successStories-home-section-description'
          dangerouslySetInnerHTML={{ __html: successStoriesHomeDescription }}
        />
        <Link to='/contact' className='successStories-home-section-button'>
          Meet Motomtech
        </Link>
      </div>
    </div>
  );
};
export default SuccessStoriesHomeComponent;
