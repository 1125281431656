import React, { useEffect } from 'react';
import SuccessStoriesHomeComponent from '../component/SuccessStories/successStoriesHomeComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import Layout from '../component/layout';
import ServicesHomeComponentV2 from '../component/servicesHomeComponentV2';
import TechStackComponent from '../component/Services/techStackComponent';
import CustomDevelopmentVideoComponent from '../component/Services/CustomDevelopment/customDevelopmentVideoComponent';

const SuccessStories = props => {
  const {
    data: {
      wpPage: {
        seo,
        homePageHeaderSection: { homeHeaderTitle, homeHeaderDescription },
        aboutUsHomeSection: {
          aboutUsHomeDescription,
          aboutUsHomeSolutionTitle,
          aboutUsHomeTitle,
          aboutUsHomeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: aboutUsHomeImage },
            },
          },
        },
        agreedHomeSection: {
          agreedHomeData,
          agreedHomeLocation,
          agreedHomeTitle,
          agreedHomeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: agreedHomeImage },
            },
          },
        },
        blogAlbaniaArticle: {
          blogAlbaniaArticleTitle,
          blogAlbaniaArticleAuthor,
          blogAlbaniaArticleText,
          blogAlbaniaArticleImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogAlbaniaArticleImage },
            },
          },
        },
        blogBuildingMvpSection: {
          blogBuildingMvpName,
          blogBuildingMvpText,
          blogBuildingMvpTitle,
          blogBuildingMvpImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogBuildingMvpImage },
            },
          },
        },
        blogMvpSection: {
          blogMvpName,
          blogMvpText,
          blogMvpTitle,
          blogMvpImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogMvpImage },
            },
          },
        },
        homePageFullCycleSection: {
          homePageFullCycleQualityDescription,
          homePageFullCycleQualityTitle,
          homePageFullCycleTitle,
          digitalApplicationImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: digitalApplicationImage },
            },
          },
        },
        jobHomeSection: {
          jobHomeSectionHybrid,
          jobHomeSectionLocation,
          jobHomeSectionTitle,
          jobHomeSectionBackgroundImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: jobHomeSectionBackgroundImage,
              },
            },
          },
        },
        projectChallengeSection: {
          projectChallengeDevelopmentDescription,
          projectChallengeDevelopmentTitle,
          projectChallengeTitle,
          projectChallengeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: projectChallengeImage },
            },
          },
        },
        projectResultsSection: {
          projectResultsDescription,
          projectResultsTitle,
          projectSectionText1,

          projectResultsImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: projectResultsImage },
            },
          },
        },
        projectSolutionsSection: {
          projectSolutionsTitle,
          projectSolutionsUserExperienceDescription,
          projectSolutionsUserExperienceTitle,
          projectSolutionsImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: projectSolutionsImage },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;

  const companies = [
    {
      image: aboutUsHomeImage,
      title: aboutUsHomeTitle,
      subtitle: aboutUsHomeDescription,
      description: aboutUsHomeSolutionTitle,
      imageLeft: true,
      path: '/purposolver',
    },
    {
      image: agreedHomeImage,
      title: agreedHomeTitle,
      subtitle: agreedHomeData,
      description: agreedHomeLocation,
      imageLeft: false,
      path: '/paramount',
    },
    {
      image: blogAlbaniaArticleImage,
      title: blogAlbaniaArticleTitle,
      subtitle: blogAlbaniaArticleAuthor,
      description: blogAlbaniaArticleText,
      imageLeft: true,
      path: '/rakuten',
    },
    {
      image: blogBuildingMvpImage,
      title: blogBuildingMvpTitle,
      subtitle: blogBuildingMvpName,
      description: blogBuildingMvpText,
      imageLeft: false,
      path: '/clean-clinic',
    },
    {
      image: blogMvpImage,
      title: blogMvpTitle,
      subtitle: blogMvpName,
      description: blogMvpText,
      imageLeft: true,
      path: '/naxxa',
    },
    {
      image: digitalApplicationImage,
      title: homePageFullCycleTitle,
      subtitle: homePageFullCycleQualityTitle,
      description: homePageFullCycleQualityDescription,
      imageLeft: false,
      path: '/oneclickapp',
    },
    {
      image: jobHomeSectionBackgroundImage,
      title: jobHomeSectionTitle,
      subtitle: jobHomeSectionLocation,
      description: jobHomeSectionHybrid,
      imageLeft: true,
      path: '/wedax',
    },
    {
      image: projectChallengeImage,
      title: projectChallengeTitle,
      subtitle: projectChallengeDevelopmentTitle,
      description: projectChallengeDevelopmentDescription,
      imageLeft: false,
      path: '/agreed',
    },
    {
      image: projectResultsImage,
      title: projectResultsTitle,
      subtitle: projectResultsDescription,
      description: projectSectionText1,
      imageLeft: true,
      path: '/bash',
    },
    {
      image: projectSolutionsImage,
      title: projectSolutionsTitle,
      subtitle: projectSolutionsUserExperienceTitle,
      description: projectSolutionsUserExperienceDescription,
      imageLeft: false,
      path: '/hyra',
    },
  ];

  return (
    <Layout seo={seo} title={title}>
      <SuccessStoriesHomeComponent
        successStoriesHomeTitle={homeHeaderTitle}
        successStoriesHomeDescription={homeHeaderDescription}
      />
      <div
        style={{
          background:
            'linear-gradient(109.39deg, #10103a 3.43%, #354688 100.02%)',
        }}
      >
        {companies.map((company, i) => (
          <div style={{ marginTop: '-150px' }} key={i}>
            <ServicesHomeComponentV2
              homeTitle={company.title}
              homeSubtitle={company.subtitle}
              homeDescription={company.description}
              homeImage={company.image}
              path={company.path}
              imageLeft={company.imageLeft}
              homeButton={'Learn more'}
              isGatsby={true}
              background={'transparent'}
            />
          </div>
        ))}
      </div>
      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "success-stories" }) {
      seo {
        metaDesc
        title
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
      }
      aboutUsHomeSection {
        aboutUsHomeDescription
        aboutUsHomeSolutionTextOne
        aboutUsHomeSolutionTextThree
        aboutUsHomeSolutionTextTwo
        aboutUsHomeSolutionTitle
        aboutUsHomeTitle
        aboutUsHomeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHomeSection {
        agreedHomeData
        agreedHomeLocation
        agreedHomeTitle
        agreedHomeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogAlbaniaArticle {
        blogAlbaniaArticleTitle
        blogAlbaniaArticleAuthor
        blogAlbaniaArticleText
        blogAlbaniaArticleImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }

      blogBuildingMvpSection {
        blogBuildingMvpName
        blogBuildingMvpText
        blogBuildingMvpTitle
        blogBuildingMvpImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogMvpSection {
        blogMvpName
        blogMvpText
        blogMvpTitle
        blogMvpImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageFullCycleSection {
        homePageFullCycleQualityDescription
        homePageFullCycleQualityTitle
        homePageFullCycleTitle
        digitalApplicationImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      jobHomeSection {
        jobHomeSectionHybrid
        jobHomeSectionLocation
        jobHomeSectionTitle
        jobHomeSectionBackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      projectChallengeSection {
        projectChallengeDevelopmentDescription
        projectChallengeDevelopmentTitle
        projectChallengeTitle
        projectChallengeImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      projectResultsSection {
        projectResultsDescription
        projectResultsTitle
        projectSectionText1
        projectResultsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      projectSolutionsSection {
        projectSolutionsTitle
        projectSolutionsUserExperienceDescription
        projectSolutionsUserExperienceTitle
        projectSolutionsImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default SuccessStories;
